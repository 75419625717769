/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */
/***************************************************************************************************
* BROWSER POLYFILLS
*/
/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js'; // Run `npm install --save classlist.js`.
import 'core-js/es/object';
/** Evergreen browsers require these. **/
import 'core-js/es/reflect';
// import 'core-js/es7/reflect';
import 'jspdf/dist/polyfills.es.js';
/** IE10 and IE11 requires the following to support `@angular/animation`. */
/** ALL Firefox browsers require the following to support `@angular/animation`. **/
import 'web-animations-js'; // Run `npm install --save web-animations-js`.
/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.
// // Rich Text Editor
// import 'quill';
import '../node_modules/hammerjs/hammer.js';
// Third party JS imports
// Page loader
// import './vendor/pace/pace.min.js';
// // Charts
import './vendor/Chart.min.js';




/***************************************************************************************************
 * APPLICATION IMPORTS
 */

(window as any).global = window;


/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

if (!Element.prototype.matches) {
    Element.prototype.matches =
        (<any>Element.prototype).matchesSelector ||
        (<any>Element.prototype).mozMatchesSelector ||
        (<any>Element.prototype).msMatchesSelector ||
        (<any>Element.prototype).oMatchesSelector ||
        (<any>Element.prototype).webkitMatchesSelector ||
        function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) { }
            return i > -1;
        };
}